import { arrayRemove, arrayUnion, collection, doc, getDocs, orderBy, query, runTransaction,  where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link,  useParams } from 'react-router-dom'
import { db, storage } from '../../firebase';
import Registbutton from '../regist_button';
import Deletegamebutton from '../delete_game_button';
import Editbutton from '../edit_button';
import Editprofilebutton from '../edit_profile';
import { getDownloadURL, ref } from 'firebase/storage';

import '../Newcss/NewProfile.css';
import '../Newcss/GameRegist_id.css';

import SNS_2 from '../SNS_2';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, ClickAwayListener, Skeleton, Tooltip } from '@mui/material';

import Linkify from 'react-linkify';
import { useTranslation } from 'react-i18next';
import NewSidebar from './NewSidebar';

import rogo from '../IDnest.jpeg'
import { Helmet } from 'react-helmet-async';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';




//改行のため
function formatTextWithLineBreaks(text) {
  return text.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

function NewProfile({
  UserInfo,
  profile_sns,
  PlayerGames,
  GameImages,
  userImageUrl,
  suggest,
  follow_user,
  follower_user,
  friend_user,
  common,
  handleImageChange,
  imageKey}) {

    const { pk } = useParams();
    const localuuid = localStorage.getItem('login');
    localStorage.setItem('page','profile');
    //言語設定
    const { t } = useTranslation();


    // if(localuuid === null){
    //   const remove_app = () => {
    //     navigate('/');
    //   }
    //   remove_app()
    // }
    

    //他のユーザーのもの
    const [another_userinfo,setanother_userinfo] = useState([]);
    const [another_PlayerGames,setanother_PlayerGames] = useState([]);
    const [another_sns,setanother_sns] = useState([]);
    const [another_userImageUrl, setanother_userImageUrl] = useState('');
    const [loading,setloading] = useState(true)

    const [Nouser_check,setNouser_check] = useState(true)

    const [anchorEl, setAnchorEl] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);
  
    const handleClick = (event, index) => {
      setAnchorEl(event.currentTarget);
      setOpenIndex(index === openIndex ? null : index); // 既に開いている場合は閉じる
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setOpenIndex(null);
    };


    //コピーしました。====名前======================
    const [open, setOpen] = React.useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

          //読み込み時画像
          const Anotherimg = () => {
            if(loading){
              return(
                <Skeleton variant="circular" className='newprofile_userimage' width={80} height={80} />
              )
            }else{
              return(
                <img className='newprofile_userimage' alt='' src={another_userImageUrl}/>
              )
            }
          }

    //コピー
    const copyToClipboard = async (text) => {
      
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Clipboard APIを使用（モダンブラウザ用）
        navigator.clipboard.writeText(text).then(() => {
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // フォールバック方法（古いブラウザやSafari用）
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // スクロールを防ぐために必要
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = "0";
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
          const successful = document.execCommand('copy');
          if (successful) {
          } else {

          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textArea);
      }
      handleTooltipOpen();
    };
    //==============ID======================
    const [open_2, setOpen_2] = React.useState(false);
    const handleTooltipClose_2 = () => {
      setOpen_2(false);
    };
  
    const handleTooltipOpen_2 = () => {
      setOpen_2(true);
    };

    //コピー
    const copyToClipboard_2 = async (text) => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Clipboard APIを使用（モダンブラウザ用）
        navigator.clipboard.writeText(text).then(() => {
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // フォールバック方法（古いブラウザやSafari用）
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // スクロールを防ぐために必要
        textArea.style.position = "fixed";
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.width = "2em";
        textArea.style.height = "2em";
        textArea.style.padding = "0";
        textArea.style.border = "none";
        textArea.style.outline = "none";
        textArea.style.boxShadow = "none";
        textArea.style.background = "transparent";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
          const successful = document.execCommand('copy');
          if (successful) {
          } else {
            console.error('Fallback: Copy command was unsuccessful');
          }
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
    
        document.body.removeChild(textArea);
      }
      handleTooltipOpen_2();
    };
    //=====================================================


    useEffect(() => {
      const fetchData = async () => {

      if(pk === localuuid){
      }else{
        setloading(true)
        //自分以外の時、その人のデータを取得＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
        //画像
        try{
          const imageUrl = await getDownloadURL(ref(storage, '/user_images/'+pk+'.jpg'));
          setanother_userImageUrl(imageUrl);
        } catch (error) {
          const imageUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
          setanother_userImageUrl(imageUrl);
        }
        //プロフィール
        const userinfoQuery = query(collection(db, 'UserInfo'), where('uuid', '==', pk));
  

        const userinfoQuerySnapshot = await getDocs(userinfoQuery);
        if (userinfoQuerySnapshot.empty) {
          setNouser_check(false)
        } else {
          setNouser_check(true)
          userinfoQuerySnapshot.forEach((doc) => {
          });
        }
        setanother_userinfo(userinfoQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
        //SNS
        const snsQuery = query(collection(db, 'SNS'), where('uuid', '==', pk));
        const snsQuerySnapshot = await getDocs(snsQuery);
        setanother_sns(snsQuerySnapshot.docs.map(doc => ({ ...doc.data() }))[0]);
        //ゲーム情報
        const gamesQuery = query(collection(db, 'PlayerGames'), where('uuid', '==', pk), orderBy("createdAt"));
        const gamesQuerySnapshot = await getDocs(gamesQuery);
        setanother_PlayerGames(gamesQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
      }}
      fetchData();
      setloading(false)
    },[])
    if(loading){
      return(
        <CircularProgress />
      )
    }



    // useEffect(() => {
    //   if(Nouser_check === null){
    //     return(
    //       <NewNotUser  
    //       userImageUrl={userImageUrl}/>
    //           )
    //   }
    // },[Nouser_check])




    
    //===================友達登録関係=======================
    const following_action = async(event) =>{
      event.preventDefault();
      //フォロー
      //フォロー側（相手に通知＆自分のリストに）
      const followRef = doc(db, "Friends", localuuid);
      const followerRef = doc(db, "Friends", pk);
    
      try {
        await runTransaction(db, async (transaction) => {
          // followingリストにpkを追加
          transaction.update(followRef, {
            following: arrayUnion(pk)
          });
    
          // followerリストにlocaluuidを追加
          transaction.update(followerRef, {
            follower: arrayUnion(localuuid)
          });
        });
    
      } catch (e) {
        console.log("Transaction failed: ", e);
      }}
    //フォロー解除
      const cancel_following = async(event) => {
        event.preventDefault();
        const followRef = doc(db, "Friends", localuuid);
        const followerRef = doc(db, "Friends", pk);
        try {
          await runTransaction(db, async (transaction) => {
            // followingリストからpkを削除
            transaction.update(followRef, {
              following: arrayRemove(pk)
            });
      
            // followerリストからlocaluuidを削除
            transaction.update(followerRef, {
              follower: arrayRemove(localuuid)
            });
          });
        } catch (error) {
          console.error('トランザクションの実行中にエラーが発生しました: ', error);
        }
        };
              //フレンドから削除＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const delete_friend = async(event) => {
        event.preventDefault();
        //フォロー側（相手に通知＆自分のリストに）
        const followRef = doc(db, "Friends", localuuid);
        const followerRef = doc(db, "Friends", pk);

        try {
          await runTransaction(db, async (transaction) => {
            // followRefの更新
            transaction.update(followRef, {
              friend: arrayRemove(pk),
              follower: arrayUnion(pk)
            });
      
            // followerRefの更新
            transaction.update(followerRef, {
              friend: arrayRemove(localuuid),
              following: arrayUnion(localuuid)
            });
      
          });
        } catch (error) {
          console.error('トランザクションの実行中にエラーが発生しました: ', error);
        }
      }
      //フォローボタン表示＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const Followbutton = () =>{
        if(localuuid){
          if (follower_user.includes(pk)) {
            return(
              <>
              <div className='profile_followbutton'>
                <span onClick={following_action}>{t('do_follow')}</span>
                {/* <p>{t('iam_followed')}</p> */}
              </div>
              </>
          );
          } else if (follow_user.includes(pk)) {
            return(
              <div className='profile_followbutton'>
              <span onClick={cancel_following}>{t('following_now')}</span>
              </div>
          );
          } else if (friend_user.includes(pk)) {
            return(
              <>
              <div className='profile_followbutton'>
              <span onClick={delete_friend}>{t('cancel_follow')}</span>
              {/* <p>{t('we_are_friend')}</p> */}
              </div>
              </>
          );
          }else{
            return(
              <div className='profile_followbutton'>
              <span onClick={following_action}>{t('do_follow')}</span>
              </div>
            )
          }
        }else{
          return ;
        }
}
//================= フォローかフレンドか==============================
const User_check = () =>{
  if(localuuid){
    if (follower_user.includes(pk)) {
      return(
        <>
        <div className='user_check_follower_user user_check'>
          <span>{t('iam_followed')}</span>
        </div>
        </>
    );
    } else if (follow_user.includes(pk)) {
      return(
        <div className='user_check_follow_user user_check'>
          
          <span>{t('following_now')}</span>

        </div>
    );
    } else if (friend_user.includes(pk)) {
      return(
        <>
        <div className='user_check_friend_user user_check'>
        <span>{t('we_are_friend')}</span>
        </div>
        </>
    );
    }else{
      return(
        <div className='profile_followbutton user_check'>
        </div>
      )
    }
  }else{
    return ;
  }
}

      //自己紹介欄
      const IntroductionComponent = ({ UserInfo }) => {
        const introduction = UserInfo.length > 0 ? UserInfo[0].introduction : '';
      
        return (
          <Linkify>
            <p className='introduction'>
              {formatTextWithLineBreaks(introduction)}
            </p>
          </Linkify>
      
        );
      }
//サイドバーかログイン画面まで生かせるか
      const Sidebarornewlogin = () => {
        if(localuuid){
          return(
            <NewSidebar
            userImageUrl={userImageUrl}
          />
          )
        }else{
          return(
            <div id="header">
            <div className='header_buttons'>
              <img className='profile_rogo' alt='' src={rogo}/>
              <Link to='/login'  className='nouser_button'>{t('signup_or_login')}</Link>
            </div>
          </div>
          )
        }
      }


    //自分以外のプロフィール＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    if(pk !== localuuid){
      return(
        
        <div  className='app'> 
              <Helmet>
        {/* {UserInfo && <title>{UserInfo[0].Username}'s Profile</title>} */}
        {/* {another_userinfo.map((user) => (
          <>
          <title>          
            {user.Username}'s Profile
          </title>
          </>
          ))} */}
          {UserInfo[0] ? (
            <title>{UserInfo[0].Username}'s Profile</title>
          ) : (
            <title>IDNect-profile</title> // 何もない場合のデフォルトタイトル
          )}
          <meta name="description" content="profile" />
      </Helmet>
        <div className='app_sidebar'>
          <Sidebarornewlogin/>
        </div>
        <div className='newprofile_body another_profile_body'>
          <h3 className='pagetitle'>{t("profile")}</h3>
          {Nouser_check === false ? (
            <p>このユーザーは存在しません。</p>
              ) : (
      <>
          <div className='newprofile_userinfo'>
            {/* userinfo */}
        
            {another_userinfo.map((user,index) => (
              <div key={user.uuid+index}>
                <div className='newprofile_userinfo_text' key={index}>

                  {/* <img className='newprofile_userimage' src={another_userImageUrl}/> */}
                  <div>
                  <Anotherimg/>
                  <User_check/>
                  </div>

                  <div className='newprofile_editprofile'>
                  <Followbutton/>
                  </div>
                </div>

                  <div>
                  <h1 className='newprofile_username'>{user.Username}</h1>
                  <p className='newprofile_userid'>@{user.UserID}</p>
                  <IntroductionComponent UserInfo={another_userinfo} />
                  </div>
              </div>
              ))}

          </div>
          <div className='newprofile_sns'>
                <SNS_2
                  sns_list={another_sns}
                />
           </div>
          {/* playergames */}
          {another_PlayerGames.map((games,index) => (
          <div className='gamelist' key={games.gamename+'_'+games.gameusername}>
          <Accordion style={{ position: 'relative', zIndex: 0 }}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls={`panel1-content-${index}`}
            id={`panel1-header-${index}`}
            style={{ position: 'relative', zIndex: 0 }}
          >
          <Typography variant="div">
            <div className='newprofile_gamelist'>
              <img className='gameimg' alt='' src={games.gameimgurl} />
              <span className='gamelist_gamename'>{games.gamename}</span>
              {games.igdb_url ? (
                <a className='igdb' target="_blank" href={games.igdb_url} rel="noopener noreferrer">
                  <span className='igdb-text'>IGDB</span>
                  <span className='com-text'>.com</span>
                </a>
              ) : (
                <span className='no-igdb'></span>
              )}
            </div>
          </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ position: 'relative', zIndex: 0 }}>
          <Typography variant="div">
            <div className='newprofile_game_info_text'>
              <span className='game_text'><span className='newprofile_name_text'>{t('name')}</span>{games.gameusername}</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('copyed')}
                placement="top"
              >
              <span className='profile_copy_text' onClick={() => copyToClipboard(games.gameusername)}>{t('copy')}</span>
              </Tooltip>
              </ClickAwayListener>
            </div>
            <div className='newprofile_game_info_text'>
              <span className='game_text'><span className='newprofile_id_text'>ID</span>{games.gameuserid}</span>
              <ClickAwayListener onClickAway={handleTooltipClose_2}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose_2}
                open={open_2}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('copyed')}
                placement="top"
              >
              <span className='profile_copy_text' onClick={() => copyToClipboard_2(games.gameuserid)}>{t('copy')}</span>
              </Tooltip>
              </ClickAwayListener>
            </div>
            </Typography>
            </AccordionDetails>
            </Accordion>

          </div>
      ))}
      </>
    )}
        </div>
        </div>
      )
    }


//自分のプロフィール＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
  return (
    <div  className='app'> 
      <Helmet>
        {/* {UserInfo && <title>{UserInfo[0].Username}'s Profile</title>} */}
        {/* {UserInfo.map((user) => (
          <>
          <title>          
            {user.Username}'s Profile
          </title>
          </>
          ))} */}
            <title>IDNect-Profile</title>
            <meta name="description" content="IDNect user profile" />
      </Helmet>
    <div className='app_sidebar'>
    <NewSidebar
      userImageUrl={userImageUrl}
      imageKey={imageKey}
    />
    </div>
    <style>
      {`
        .profileside {
          font-weight: bold;
          background-color: rgb(231, 231, 231);
        }
      `}
      </style>
    <div className='newprofile_body'>
    <h3 className='pagetitle'>{t("profile")}</h3>

    <div key={'Profile'}>
      {/*＝＝＝＝＝ プロフィール（名前・画像・ID等） ＝＝＝＝＝＝＝＝＝＝＝＝*/}
      <div className='newprofile_userinfo'>
          {UserInfo.map((user,index) => (
            <div key={user.uuid+index}>
            <div className='newprofile_userinfo_text' key={index}>
              <img  className='newprofile_userimage' alt='' src={userImageUrl} />
              <div className='newprofile_editprofile'>
                  <Editprofilebutton
                    imgurl={userImageUrl}
                    username={user.Username}
                    userid={user.UserID}
                    introduction={user.introduction}
                    sns_list={profile_sns}
                    handleImageChange={handleImageChange}  
                  />
              </div>
            </div>
            <div>
            <h1 className='newprofile_username'>{user.Username}</h1>
            <p className='newprofile_userid'>@{user.UserID}</p>
            <IntroductionComponent UserInfo={UserInfo} />
            </div>
            </div>
          ))}
      </div>
      <div className='newprofile_sns'>
        <SNS_2
          sns_list={profile_sns}
        />
      </div>
      <Registbutton className='regist_button' suggest={suggest}/>

      {/* ＝＝＝＝＝＝＝＝＝＝＝＝＝登録ゲーム情報＝＝＝＝＝＝＝＝＝＝＝ */}
      {PlayerGames.map((games,index) => (
          <div className='gamelist' key={games.gamename+'_'+games.gameusername}>
          <Accordion>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            aria-controls={`panel1-content-${index}`}
            id={`panel1-header-${index}`}
          >
          <Typography variant="div">
          <div className='newprofile_gamelist'>
          <img
            className="gameimg"
            alt=''
            src={games.gameimgurl}
          />
              <span className='gamelist_gamename'>{games.gamename}</span>
              <div className='buttons'>


                {/* <Editbutton
                  document_id={games.id}
                  uid={localuuid}
                  profile_gamename={games.gamename}
                  profile_gameusername={games.gameusername}
                  profile_gameuserid={games.gameuserid}
                  gameimg={games.gameimgurl}
                />
                <Deletegamebutton
                  index={index}
                  uid={localuuid}
                  profile_gamename={games.gamename}
                  profile_gameusername={games.gameusername}
                  profile_gameuserid={games.gameuserid}
                  gameimg={games.gameimgurl}
                /> */}
                    <div>
            <Button
            className='menu'

            id={`button-${index}`}
            aria-controls={openIndex === index ? `menu-${index}` : undefined}
            aria-haspopup="true"
            aria-expanded={openIndex === index ? 'true' : undefined}
            onClick={(event) => {
              event.stopPropagation(); // これを追加
              handleClick(event, index);
            }}
          >
            <MoreHorizIcon/>
          </Button>
          <Menu
            id={`menu-${index}`}
            anchorEl={anchorEl}
            open={openIndex === index}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': `button-${index}`,
            }}
          >
            <MenuItem className='menuitems'>
              <Editbutton
                    document_id={games.id}
                    uid={localuuid}
                    profile_gamename={games.gamename}
                    profile_gameusername={games.gameusername}
                    profile_gameuserid={games.gameuserid}
                    gameimg={games.gameimgurl}
                    menuhandleClose={handleClose}
                  />
            </MenuItem>
            <MenuItem className='menuitems'>                
              <Deletegamebutton
                  index={index}
                  uid={localuuid}
                  profile_gamename={games.gamename}
                  profile_gameusername={games.gameusername}
                  profile_gameuserid={games.gameuserid}
                  gameimg={games.gameimgurl}
                  menuhandleClose={handleClose}
                />
            </MenuItem>
            {games.igdb_url && 
            <MenuItem className='menuitems'><a className='igdb' target="_blank" href={games.igdb_url} rel="noopener noreferrer">
                {/* <span className='igdb-text'>IGDB</span>
                <span className='com-text'>.com</span> */}
                idgb.com</a>
            </MenuItem>}
      </Menu>
    </div>

              </div>
            </div>
          </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="div">
            {/* <p className='game_text'>名前：{games.gameusername}</p><span className='profile_copy_button'><a className='profile_copy_text'>コピー</a></span>
            <p className='game_text'>  ID:{games.gameuserid}</p><span className='profile_copy_button'><a className='profile_copy_text'>コピー</a></span> */}
            <div className='newprofile_game_info_text'>
              <span className='game_text'><span className='newprofile_name_text'>{t('name')}</span>{games.gameusername}</span>
              <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('copyed')}
                placement="top"
              >
              <span className='profile_copy_text' onClick={() => copyToClipboard(games.gameusername)}>{t('copy')}</span>
              </Tooltip>
              </ClickAwayListener>
            </div>
            <div className='newprofile_game_info_text'>
              <span className='game_text'><span className='newprofile_id_text'>ID</span>{games.gameuserid}</span>
              <ClickAwayListener onClickAway={handleTooltipClose_2}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose_2}
                open={open_2}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('copyed')}
                placement="top"
              >
              <span className='profile_copy_text' onClick={() => copyToClipboard_2(games.gameuserid)}>{t('copy')}</span>
              </Tooltip>
              </ClickAwayListener>
            </div>
            </Typography>
            </AccordionDetails>
            </Accordion>

          </div>
      ))}
    </div>
    </div>
    </div>
  )
}

export default NewProfile