import React, { memo, useEffect,  useState } from 'react'
import { Autocomplete, Box, Button, CircularProgress, Divider, IconButton, InputBase, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

//icon
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


import './regist_button.css'

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlaystation, faSteam, faXbox } from '@fortawesome/free-brands-svg-icons';



//スマホとスマホ外でくくっているが、スマホ外っていうのが曖昧なのでpcとする。

const Registbutton = memo(({suggest}) => {
  //dialog
    const [open, setOpen] = React.useState(false);
    //モバイル
    const [mobile_regist_open, setmobile_regist_open] = React.useState(false);
    const [mobile_regist_open_2, setmobile_regist_open_2] = React.useState(false);
    //pcとか
    const [pc_regist_open, setpc_regist_open] = React.useState(false);
    const [pc_regist_open_2, setpc_regist_open_2] = React.useState(false);
    //playstation(id)
    const [id_playstation, setid_playstation] = React.useState(false);
    const [id_name, setid_name] = React.useState(false);
    const [consumer_id_url, setconsumer_id_url] = React.useState(false);

    const [consumer_id, setconsumer_id] = React.useState();
    const [consumer_id_number, setconsumer_id_number] = React.useState();

    





    //suggest関係

    const [suggestions, setSuggestions] = useState([]);
    const [display_suggest, setdisplay_suggest] = useState(' ');



    const [appName,setappName] = useState('');
    const [AppImage,setAppImage] = useState([]);
    const [GameName,setGameName] = useState([]);
    const [appid,setappId] = useState([]);
    const [igdb_url,setigdb_url] = useState([]);





    // const [gamename,setgamename] = useState('');
    const [gameuserid,setgameuserid] = useState('');
    const [gameusername,setgameusername] = useState('');
    const [gamememo,setgamememo] = useState('');


    //mobile_game選択
    const [serach_result_gamename,setserach_result_gamename] = useState([]);
    const [serach_result_gameimg,setserach_result_gameimg] = useState([]);
    const [serach_result_gameid,setserach_result_gameid] = useState([]);


    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const { t,i18n } = useTranslation();

    const[loading,setloading] = useState(false)



    //サジェスト関係============================================
    //データをTodolistに入れる。
    // console.log('登録ボタン')
    // useEffect(() => {
    //   if(got_suggest.current === false){
    //     (async () => {
    //       const list = await getToDoList();
    //       setTodoList(list);
    //       got_suggest.current = true;
    //     })();
    //   }
    //    }, []);

      const handleInputChange = (event) => {
        const inputValue = event.target.value;
        // サジェストを生成
        const newSuggestions = generateSuggestions(inputValue).slice(0, 30); 
        setSuggestions(newSuggestions);
        setGameName(inputValue)

      };
    
      // const generateSuggestions = (input) => {
      //   // ここでサジェストのロジックを実装する
      //   // 例えば、入力された文字列と一致する可能性のあるサジェストを取得するAPIを呼び出すなど
      //     if (Array.isArray(todoList)) {
      //         return todoList.filter(suggestion =>
      //             suggestion.name.toLowerCase().includes(input.toLowerCase())
      //         );
      //  }
      // };

      const generateSuggestions = (input) => {
        if (Array.isArray(suggest)) {
          return suggest
            .filter(suggestion =>
              suggestion.name.toLowerCase().includes(input.toLowerCase()) ||
              suggestion.japan_name.toLowerCase().includes(input.toLowerCase())
            )
        }
      };

      useEffect(() => {
        if (i18n.language === 'ja'){
          const mappedSuggestions = suggestions.map(item => ({
            label: item['japan_name'],
            image_url: item['image_url'], // ラベルをvalueとして使う例
            url: item['url'] 
          }));
          setdisplay_suggest(mappedSuggestions)

        }else if(i18n.language === 'en'){
          const mappedSuggestions = suggestions.map(item => ({
            label: item['name'],
            image_url: item['image_url'], // ラベルをvalueとして使う例
            url: item['url'] 
          }));
          setdisplay_suggest(mappedSuggestions)
        }
        // const mappedSuggestions = suggestions.map(item => ({
        //   label: item['japan_name'],
        //   image_url: item['image_url'] // ラベルをvalueとして使う例
        // }));
        // setdisplay_suggest(mappedSuggestions)
      },[suggestions,i18n.language])

      //ゲーム選択のの次の登録のための画像を準備
      // useEffect(() => {
      //   const cover = todoList.filter(suggestion =>
      //     suggestion.japan_name.toLowerCase() === GameName.toLowerCase()
      //   )[0];
      //   if (cover){
      //     setAppImage(cover['image_url'])
      //   }
      // },[GameName])
      //autocompleteのフィルタリングを無効に
      const noFilterOptions = (options) => options;
      //===============================================================
    const GameRegist = async(event) => {
      event.preventDefault();


      const docRef = await addDoc(collection(db, "PlayerGames"), {
        uuid: user.uid,
        gamename: GameName,
        gameuserid:gameuserid,
        gameusername:gameusername,
        gameimgurl:AppImage,
        gameid: appid,
        igdb_url:igdb_url,
        memo:gamememo,
        createdAt: serverTimestamp()
      });
      console.log('データ登録')
      navigate('/Profile/'+user.uid);
      setmobile_regist_open_2(false);
      setpc_regist_open_2(false);
      setGameName('')
      setAppImage('')
      setappName('')
      setappId('');
      setgamememo('')
      
      const local_PlayerGames = localStorage.getItem('PlayerGames');
      const dataArray = local_PlayerGames ? JSON.parse(local_PlayerGames) : [];
      const newdata = {
        uuid: user.uid,
        gamename: GameName,
        gameuserid:gameuserid,
        gameusername:gameusername,
        gameimgurl:AppImage,
        gameid: appid,
        createdAt: serverTimestamp()
      }
      dataArray.push(newdata);
      localStorage.setItem('PlayerGames', JSON.stringify(dataArray));
    };
//家庭用ゲーム機登録
    const Consumer_id_Regist = async(event) => {
      event.preventDefault();


      const docRef = await addDoc(collection(db, "PlayerGames"), {
        uuid: user.uid,
        gamename: id_name,
        gameuserid:consumer_id,
        gameusername:"",
        gameimgurl:consumer_id_url,
        gameid: consumer_id_number,
        createdAt: serverTimestamp()
      });
      console.log('データ登録')
      navigate('/Profile/'+user.uid);
      handleClose_id_playstation()

    };

    //dialog開くためのもの＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    //モバイルアプリ登録画面
    const mobiledialobClose = () => {
      setmobile_regist_open(false);
    };
    const mobileopen = () => {
      handleClose();
      setmobile_regist_open(true);
    }
    const mobileback = () => {
      handleClickOpen();
      setmobile_regist_open(false);
    }
    //モバイル登録画面２
    const mobiledialobClose_2 = () => {
      setmobile_regist_open_2(false);
    };
    const mobileopen_2 = () => {
      setmobile_regist_open(false);
      setmobile_regist_open_2(true);
      setigdb_url('')
    }
    const mobileopen_2_back = () => {
      setmobile_regist_open(true);
      setmobile_regist_open_2(false);
    }
    //pcゲーム等登録画面
    const pcdialobClose = () => {
      setpc_regist_open(false);
    };
    const pcopen = () => {
      handleClose();
      setpc_regist_open(true);
    }
    const pcopen_back = () => {
      handleClickOpen();
      setpc_regist_open(false);
    }
    //pcゲーム等登録画面２
    const pcdialobClose_2 = () => {
      setpc_regist_open_2(false);
    };
    const pcopen_2 = () => {
      const substring = '//images.igdb.com/igdb/image/upload/t_thumb/';
      if(AppImage.startsWith('/')){

        setappId(AppImage.replace(substring, '').toString())
        setAppImage('https:'+AppImage)
      }
      setpc_regist_open(false);
      setpc_regist_open_2(true);
    }
    const pcopen_2_back = () => {
      setpc_regist_open(true);
      setpc_regist_open_2(false);
    }

    const click_mobile_game = (event,img,name,id) => {
      event.preventDefault();
      setAppImage(img)
      setGameName(name)
      setappId(id)
      mobileopen_2()
    }

    //playstation_id
    const handleClickOpen_id_playstation = () => {
      setid_name('PlayStation')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fplaystation_nologo.png?alt=media&token=97325951-070c-42eb-9566-eda41e82cb4a")
      setconsumer_id_number(2)
      handleClose()
      setid_playstation(true);
    };

    const handleClickOpen_id_xbox = () => {
      setid_name('Xbox')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fxbox_nologo.png?alt=media&token=9e927e43-a636-49bb-9534-d2bceb8d4b13")
      setconsumer_id_number(3)
      handleClose()
      setid_playstation(true);
    };
    const handleClickOpen_id_steam = () => {
      setid_name('Steam')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fsteam_nologo.png?alt=media&token=fbb7cdb9-7a2f-4325-b316-2bfb01964676")
      setconsumer_id_number(1)
      handleClose()
      setid_playstation(true);
    };
    const handleClickOpen_id_switch = () => {
      setid_name('Nintendo Switch')
      setconsumer_id_url("https://firebasestorage.googleapis.com/v0/b/fir-trial2-425ff.appspot.com/o/nologo_consumergame%2Fnitendoswitch_nologo.png?alt=media&token=525418a7-a443-4935-b85b-93c4ef89ba8b")
      setconsumer_id_number(0)
      handleClose()
      setid_playstation(true);
    };
  
    const handleClose_id_playstation = () => {
      setid_playstation(false);
    };

    const idback = () => {
      handleClickOpen();
      setid_playstation(false);
    }

    

      //itunes関係＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
      const handleChange = (e) => {
        setappName(e.target.value);
      };

      const handleSubmit = async (event) => {
        // event.preventDefault();
        setloading(true)
        let itunes_url;
        if (i18n.language === 'ja'){
          itunes_url = `https://itunes.apple.com/search?term=${appName}&entity=software&country=jp&genreId=6014&limit=4`;

        }else if(i18n.language === 'en'){

          itunes_url = `https://itunes.apple.com/search?term=${appName}&entity=software&country=us&genreId=6014&limit=4`;
        }
        try {
            const response = await fetch(itunes_url);
            const data = await response.json();
            const results = data.results
            
            if (results.length > 0) {

                const imageUrl = results.map(item => item.artworkUrl100);
                const appName = results.map(item => item.trackName);
                const str_appid = results.map(item => item.trackId.toString());


                if (imageUrl) {
                    setserach_result_gameimg(imageUrl);
                    setserach_result_gamename(appName);
                    setserach_result_gameid(str_appid)
                    // Firestoreへの書き込みはここに追加
                } else {
                    console.error('Failed to get image from URL');
                }
            } else {
                console.error('No results found');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setloading(false)
        };
    const handleKeyDown = async(event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // デフォルトのEnterキー動作を防止する

        handleSubmit();
      }
    };

    const Loadingdiaplay = () => {
      if(loading){
        return (
          <CircularProgress className='kurukuru'/>
        )
      }else{
        return null;
      }
    }
  return (
    <React.Fragment>
        <div className='regist'>
        <p className='profile_regist_button' onClick={handleClickOpen}>{t("regist_game")}</p>
        </div>
        {/* //ダイアログ1（スマホかスマホ以外が選択）＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
        <Dialog
        open={open}
        onClose={handleClose}

        >
        <DialogTitle className=''>{t("regist_game")}</DialogTitle>
        <DialogContent>
        {/* <div className='mobile_or_pc'>
            <div className='mobile_game_select' onClick={mobileopen}>
              <PhoneIphoneIcon className='iphoneicon'  style={{ fontSize: 60 }}/>
              <p>スマホゲームを</p>
              <p>登録</p>
            </div>
            <div className='pc_game_select' onClick={pcopen}>
              <SportsEsportsIcon className='pcgameicon' style={{ fontSize: 60 }}/>
              <p>・PC</p>
              <p>・playstation</p>
              <p>・switch</p>
            </div>
        </div> */}
        <div className='profile_select_mobile_pc'>
          <div className='mobile_select' onClick={mobileopen}>
              <PhoneIphoneIcon className='iphoneicon'  style={{ fontSize: 40 }}/>
              <p className='sumahoge-mu'>{t("Mobilegame")}</p>
          </div>
          <div className='pc_select' onClick={pcopen}>
              <SportsEsportsIcon className='pcgameicon' style={{ fontSize: 40 }}/>
              <p className='pcgamenado'>{t("PC_playstation_switch")}</p>
              {/* <p>・playstation</p>
              <p>・switch</p> */}
          </div>
          <p>ID</p>
          <div>
          <div className='id_regist playstation' onClick={handleClickOpen_id_playstation}>
            <FontAwesomeIcon icon={faPlaystation} />
            <span className='consumergame_name'>PlayStation</span>
          </div>
          <div className='id_regist xbox' onClick={handleClickOpen_id_xbox}>
            <FontAwesomeIcon icon={faXbox} />
            <span className='consumergame_name'>Xbox</span>
          </div>
          <div className='id_regist steam' onClick={handleClickOpen_id_steam}>
            <FontAwesomeIcon icon={faSteam}/>
            <span className='consumergame_name'>Steam</span>
          </div>
          <div className='id_regist switch' onClick={handleClickOpen_id_switch}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-nintendo-switch" viewBox="0 0 16 16">
            <path d="M9.34 8.005c0-4.38.01-7.972.023-7.982C9.373.01 10.036 0 10.831 0c1.153 0 1.51.01 1.743.05 1.73.298 3.045 1.6 3.373 3.326.046.242.053.809.053 4.61 0 4.06.005 4.537-.123 4.976-.022.076-.048.15-.08.242a4.136 4.136 0 0 1-3.426 2.767c-.317.033-2.889.046-2.978.013-.05-.02-.053-.752-.053-7.979Zm4.675.269a1.621 1.621 0 0 0-1.113-1.034 1.609 1.609 0 0 0-1.938 1.073 1.9 1.9 0 0 0-.014.935 1.632 1.632 0 0 0 1.952 1.107c.51-.136.908-.504 1.11-1.028.11-.285.113-.742.003-1.053ZM3.71 3.317c-.208.04-.526.199-.695.348-.348.301-.52.729-.494 1.232.013.262.03.332.136.544.155.321.39.556.712.715.222.11.278.123.567.133.261.01.354 0 .53-.06.719-.242 1.153-.94 1.03-1.656-.142-.852-.95-1.422-1.786-1.256Z"/>
            <path d="M3.425.053a4.136 4.136 0 0 0-3.28 3.015C0 3.628-.01 3.956.005 8.3c.01 3.99.014 4.082.08 4.39.368 1.66 1.548 2.844 3.224 3.235.22.05.497.06 2.29.07 1.856.012 2.048.009 2.097-.04.05-.05.053-.69.053-7.94 0-5.374-.01-7.906-.033-7.952-.033-.06-.09-.063-2.03-.06-1.578.004-2.052.014-2.26.05Zm3 14.665-1.35-.016c-1.242-.013-1.375-.02-1.623-.083a2.81 2.81 0 0 1-2.08-2.167c-.074-.335-.074-8.579-.004-8.907a2.845 2.845 0 0 1 1.716-2.05c.438-.176.64-.196 2.058-.2l1.282-.003v13.426Z"/>
            </svg>  
            <span className='consumergame_name'>Nintendo switch</span>
          </div>
          </div>
        </div>
        </DialogContent>
        <DialogActions>
            <Button type='submit' onClick={handleClose}>Cancel</Button>
            {/* <Button type='submit' type='submit' onClick={GameRegist}>登録</Button> */}
        </DialogActions>
        </Dialog>
        {/* //ダイアログ２（スマホゲームでゲーム検索）＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
        <Dialog
          open={mobile_regist_open}
          onClose={mobiledialobClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event) => {
              event.preventDefault();
              // const formData = new FormData(event.currentTarget);
              // const formJson = Object.fromEntries(formData.entries());
              // const email = formJson.email;
              handleClose();
              },
          }}
          >
          <DialogTitle className=''>
            <div className='dialog_title'>
              <div className='regist_button_back_button' onClick={mobileback}>
                <ArrowBackIcon className='back_button'/>
              </div>
              <span>{t("search_game")}</span>
            </div>
            </DialogTitle>
          <DialogContent>
            <p>{t("search_game_text")}</p>
            <div className='mobile_suggest_game'>
                  {/* <TextField type='text' value={appName} onChange={handleChange} label="ゲーム名" variant="standard" />
              <button type='hidden' onClick={handleSubmit}>検索</button> */}
              {/* <SearchIcon /> */}
              <Paper
              // component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width:250 }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t("game_name")}
                // inputProps={{ 'aria-label': 'search google maps' }}
                value={appName} 
                // onChange={(e) => setappName(e.target.value)}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </Paper>
            <Loadingdiaplay/>
              {/* <div className='mobile_suggest_game_info'>
                <img className='regist_button_mobile_appimg' src={AppImage} />
                <p className='regist_button_mobile_gametitle'>{t("game_name")}：{GameName}</p>
              </div> */}
              {serach_result_gameimg.map((img,index) => (
              <>
                  <div className='mobile_suggest_game_info' onClick={(e) => {click_mobile_game(e,img,serach_result_gamename[index],serach_result_gameid[index])}}>
                    <img className='regist_button_mobile_appimg' alt='' src={img} />
                    {/* <p className='regist_button_mobile_gametitle'>{t("game_name")}：{serach_result_gamename[index]}</p> */}
                    <span className='regist_button_mobile_gametitle'>{serach_result_gamename[index]}</span>

                  </div>
              </>
              ))}
            </div>

          </DialogContent>
          <DialogActions>
              <Button type='submit' onClick={mobiledialobClose}>Cancel</Button>
              {/* <Button type='submit' type='submit' onClick={GameRegist}>登録</Button> */}
              {/* <Button  type="button" onClick={mobileopen_2}>{t("Regist_This_Game")}</Button> */}
          </DialogActions>
        </Dialog>
        {/* ダイアログ３（名前ID記入）＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
        <Dialog
          open={mobile_regist_open_2}
          onClose={mobiledialobClose_2}
          PaperProps={{
              component: 'form',
              onSubmit: (event) => {
              event.preventDefault();
              // const formData = new FormData(event.currentTarget);
              // const formJson = Object.fromEntries(formData.entries());
              // const email = formJson.email;
              handleClose();
              },
          }}
          >
          <DialogTitle className=''>
            <div className='dialog_title'>
              <div className='regist_button_back_button' onClick={mobileopen_2_back}>
                <ArrowBackIcon className='back_button'/>
              </div>
              <span>{t("search_game")}</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <p>{t("Enter_name_id")}</p>
            <div className='dialog3'>
              <img className='appimg' alt='' src={AppImage} />
              <span className='gamename'>{GameName}</span>
            </div>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label={t("name")}
            type="text"
            fullWidth
            variant="standard"
            value={gameusername}
            onChange={(event) => setgameusername(event.target.value)}
            />
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            value={gameuserid}
            onChange={(event) => setgameuserid(event.target.value)}
            />
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label="MEMO"
            type="text"
            fullWidth
            variant="standard"
            value={gamememo}
            onChange={(event) => setgamememo(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
              <Button type='submit' onClick={mobiledialobClose_2}>Cancel</Button>
              <Button  type='submit' onClick={GameRegist}>{t("regist")}</Button>
          </DialogActions>
        </Dialog>
        {/* ダイアログ４（ｐｃ等ゲーム選択）＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
        <Dialog
          open={pc_regist_open}
          onClose={pcdialobClose}
          // PaperProps={{
          //     component: 'form',
          //     onSubmit: (event) => {
          //     event.preventDefault();
          //     const formData = new FormData(event.currentTarget);
          //     const formJson = Object.fromEntries(formData.entries());
          //     const email = formJson.email;
          //     handleClose();
          //     },
          // }}
          >
          <DialogTitle className=''>
            <div className='dialog_title'>
              <div className='regist_button_back_button' onClick={pcopen_back}>
                <ArrowBackIcon className='back_button'/>
              </div>
              <span>{t("search_game")}</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className='regist_button_suggest_pc_game'>
          <Autocomplete
            id="country-select-demo"
            // inputValue={GameName}
            onInputChange={(event, newInputValue) => {
              //サジェストを押したとき、ある時
              if (newInputValue) {
              setGameName(newInputValue);
              }

              //クリックしたときゲーム画像　116
              let cover;
              if (i18n.language === 'ja'){
                cover = suggest.filter(suggestion =>
                  suggestion.japan_name.toLowerCase() === newInputValue.toLowerCase()
                )[0];
      
              }else if(i18n.language === 'en'){
                cover = suggest.filter(suggestion =>
                  suggestion.name.toLowerCase() === newInputValue.toLowerCase()
                )[0];
              }

              if (cover){
                setAppImage(cover['image_url'])
                setigdb_url(cover['url'])
              }


              }}
            sx={{ width: 240 }}
            options={display_suggest}
            // 日本語入力しても英語が出るように
            filterOptions={noFilterOptions}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, } }} {...props}>
                <div className='autocomplete_box'>
                <img
                className='autocomplete_img'
                  loading="lazy"
                  width="40px"
                  srcSet={option.image_url}
                  src={option.image_url}
                  alt=""
                />
                <span className='autocomplete_label'>{option.label}</span>
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
              width="40px"
                onChange={handleInputChange}
                {...params}
                label={t("game_name")}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  // autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          </div>
          </DialogContent>
          <DialogActions>
            <div className='regist_button_game_select'>
              <Button type='submit' onClick={pcdialobClose}>Cancel</Button>
              {/* <Button type='submit' type='submit' onClick={GameRegist}>登録</Button> */}
              <Button  type="button" onClick={pcopen_2}>{t("Regist_This_Game")}</Button>
            </div>
          </DialogActions>
        </Dialog>
        {/* ダイアログ5（ｐｃ等名前ID記入）＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝ */}
        <Dialog
          open={pc_regist_open_2}
          onClose={pcdialobClose_2}
          // PaperProps={{
          //     component: 'form',
          //     onSubmit: (event) => {
          //     event.preventDefault();
          //     const formData = new FormData(event.currentTarget);
          //     const formJson = Object.fromEntries(formData.entries());
          //     const email = formJson.email;
          //     handleClose();
          //     },
          // }}
          >
          <DialogTitle className=''>
            <div className='dialog_title'>
              <div className='regist_button_back_button' onClick={pcopen_2_back}>
                <ArrowBackIcon className='back_button'/>
              </div>
              <span>{t("regist_game")}</span>
            </div>
          </DialogTitle>
          <DialogContent>
          {GameName}
            <p>{t("Enter_name_id")}</p>
            <div className='dialog3'>
            <img className='appimg' alt='' src={AppImage} />
              <span className='gamename'>{GameName}</span>
            </div>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label={t("name")}
            type="text"
            fullWidth
            variant="standard"
            value={gameusername}
            onChange={(event) => setgameusername(event.target.value)}
            />
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            value={gameuserid}
            onChange={(event) => setgameuserid(event.target.value)}
            />
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label="MEMO"
            type="text"
            fullWidth
            variant="standard"
            value={gamememo}
            onChange={(event) => setgamememo(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
              <Button type='submit' onClick={pcdialobClose_2}>Cancel</Button>
              <Button type='submit' onClick={GameRegist}>{t("regist")}</Button>
          </DialogActions>
        </Dialog>
         {/* ===============playstation=============== */}
         <Dialog
          open={id_playstation}
          onClose={handleClose_id_playstation}
          >
          <DialogTitle className=''>
            <div className='dialog_title'>
              <div className='regist_button_back_button' onClick={idback}>
                <ArrowBackIcon className='back_button'/>
              </div>
              {/* <a>{t("id入力")}</a> */}
              {id_name}
            </div>
            </DialogTitle>
          <DialogContent>
            <TextField
            autoFocus
            margin="dense"
            id="name"
            name="text"
            label='ID'
            type="text"
            fullWidth
            variant="standard"
            value={consumer_id}
            onChange={(event) => setconsumer_id(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
              <Button type='submit' onClick={handleClose_id_playstation}>Cancel</Button>
              <Button type='submit' onClick={Consumer_id_Regist}>{t('regist')}</Button>


              {/* <Button type='submit' type='submit' onClick={GameRegist}>登録</Button> */}
              {/* <Button  type="button" onClick={mobileopen_2}>{t("Regist_This_Game")}</Button> */}
          </DialogActions>
        </Dialog>
    </React.Fragment>

  )
})

export default Registbutton;